import axios from "axios";
import {LOCAL_STATIONS_GET_FAIL, LOCAL_STATIONS_GET_REQUEST, LOCAL_STATIONS_GET_SUCCESS } from "../constants/constants";
import { getUrl } from './../helpers/getUrl';
import { decryptData } from "../utils/DecryptLocalStorage";
const storage = localStorage.getItem("alpha") ? localStorage.getItem("alpha") : "{}"
const data = decryptData(storage, process.env.REACT_APP_SKEY);
const token = data && data.token;


// console.log("token", token)


// export function localStation() {
//   return async (dispatch) => {
//     dispatch({ type: LOCAL_STATIONS_GET_REQUEST });
//     try {
//       const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/guest/stations`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           "Accept": "application/json",
//         },
//       });
//       const data = await res.json();
//       if(!res.ok){
//         dispatch({ type: LOCAL_STATIONS_GET_FAIL, payload: data.message || 'Unknown error occurred' });
//         return;
//       }

//       if(data.error){
//         dispatch({ type: LOCAL_STATIONS_GET_FAIL, payload: data.message });
//       }else{
//         dispatch({ type: LOCAL_STATIONS_GET_SUCCESS, payload: data });
//       }
//     }catch(error){
//       const message = error.response && error.response.data
//       ? error.response.data.message
//       : error.message;
//     dispatch({ type: LOCAL_STATIONS_GET_FAIL, payload: message });
//       dispatch({ type: LOCAL_STATIONS_GET_FAIL, payload: error.message });
//     }
//   };
// }

const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`
  },
};

export function localStation() {
  return async (dispatch) => {
    dispatch({ type: LOCAL_STATIONS_GET_REQUEST });

    try{
      const url = `${getUrl(
        process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION,
        process.env.REACT_APP_ORDER_BASE_URL
      )}/order/guest/stations`
      const res = await axios.get(url, config);

      const data = res.data;

      if(data.error){
        dispatch({ type: LOCAL_STATIONS_GET_FAIL, payload: data.error || data.message });
      }else{
        dispatch({ type: LOCAL_STATIONS_GET_SUCCESS, payload: data });
      }

    }catch(error){
      const message = error.response?.data?.message || error.message;
      dispatch({ type: LOCAL_STATIONS_GET_FAIL, payload: message });
    }
  };
}
