import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { BsArrowLeft } from "react-icons/bs";
import PropagateLoader from 'react-spinners/PropagateLoader';
import BulkProductCard from './BulkProductCard';
import { AppContext } from '../../../../AppContexts/AppContext';
import { actionTypes } from '../../../../AppContexts/constants';
import { useBulkProductUploadMutation } from '../../../../Actions/BulkUpload/BulkUpload';
import { toast } from 'react-toastify';
import { useLocalStorage } from '../../../../utils/useLocalStorage';
import { DisplayContext } from './../../../../AppContexts/DisplayContext';

import "./bulk.css";

const override = {
  margin: "0",
  borderColor: "none",
  height: "50px",
  width: "100%",
  alignItems: "center",
  justifyContent: "center"
};

const BulkProducts = () => {
  const [isHovered, setIsHovered] = useState(false);
  const {getItem, removeItem} = useLocalStorage("batch_upload_items")
  const [bulkProductUpload, {isError, error, isSuccess, data, isLoading}] = useBulkProductUploadMutation()
  const {uploadDispatch, state, setUploadSuccessData, setEditIndex, setPriceValue} = useContext(AppContext);
  const {setCheckPickup} = useContext(DisplayContext)
  const itemsLength = getItem().length;
  const uploadData = (state && state.batch_upload_items) || getItem();
  const navigate = useNavigate();


  const body = uploadData.map(({sub_category_sizes,state,prefilledData,success_data, get_category, batch_upload_items, lga, ...rest}) => rest);

  

  const handleBulkUpload = async () => {
      try {
        await bulkProductUpload({body});
      } catch (error) {
        toast.error(`Upload failed ${error}`)
      }
  };

  const clearState = () => {
       uploadDispatch({type: actionTypes.CLEAR_STATE});
       navigate("/merchant/store/upload");
       setCheckPickup("");
       setEditIndex(null);
       setPriceValue("");
  }

  useEffect(() => {
    if(isError) {
      const errorMessage = error?.data ? error.data.message : error?.error;
      toast.error(`${errorMessage}`);
    }
  }, [isError, error?.error, error?.data]);

  useEffect(() => {
     if(isSuccess){
          setUploadSuccessData(data?.results)
          navigate("/merchant/store/upload/success");
          removeItem()
     }
  }, [isSuccess, setUploadSuccessData, removeItem, data?.results, navigate]);

  const backTo = () => {
      navigate(-1);
      uploadDispatch({type: actionTypes.CLEAR_STATE});
      setPriceValue("")
      setCheckPickup("")
      setEditIndex(null)
  }

  return (
    <article className="upload-section">
        <div 
          style={getBtnStyle(isHovered)} 
          onClick={backTo}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <BsArrowLeft size={20}/> Back
        </div>
        <div className="upload-header bulk-upload-header">
        
            <div>
                <div>Upload Product</div>
                <div><div className="green-dot"/> Bulk upload {itemsLength ? `(${itemsLength})` : "0"}</div>
            </div>
            <div className='bulk-actions'>
                <div className='clear-btn' onClick={clearState}>
                      <div className='add-new-plus'>
                        <Plus size={14}/>
                      </div>
                      <span>Add new product</span>
                </div>
              <button className='btn__submit-load' disabled={itemsLength === 0} onClick={handleBulkUpload} type="button">{isLoading ? (  <div className="loader-container">
                  <PropagateLoader  color={"#FFFFFF"} loading={isLoading} cssOverride={override} />
      </div>) : "Finish Upload"}</button>
            </div>
        </div>
        {
          itemsLength === 0 ? <h5 className='text-center py-4'>
              No item to upload
          </h5> : <BulkProductCard />
        }
   </article>
  )
}

export default BulkProducts;

const getBtnStyle = (isHovered) => ({
  display: "flex",
  alignItems: "center", 
  gap: "5px", 
  marginBottom: "10px",
  fontSize: "14px",
  fontWeight: 600,
  cursor: "pointer",
  color: isHovered ? "#192150" : "rgba(0, 0, 0, 0.6)",
  transition: "color 0.3s ease",
});