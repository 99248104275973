import axios from 'axios';
import { HOME_DELIVERY_GET_FAIL, HOME_DELIVERY_GET_REQUEST, HOME_DELIVERY_GET_SUCCESS } from "../constants/constants";
import { getUrl } from './../helpers/getUrl';

import { decryptData } from "../utils/DecryptLocalStorage";

const storage = localStorage.getItem("alpha") ? localStorage.getItem("alpha") : "{}"
const data = decryptData(storage, process.env.REACT_APP_SKEY);
const token = data && data.token;

const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`
  },
};

export function HomeDelivery(){
  return async (dispatch) => {
    dispatch({ type: HOME_DELIVERY_GET_REQUEST });

    try {
      const url = `${getUrl(
        process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION,
        process.env.REACT_APP_ORDER_BASE_URL
      )}/order/guest/homedelivery_address`
      const res = await axios.get(url, config);
      const data = res.data;
      if(data.error){
        dispatch({ type: HOME_DELIVERY_GET_FAIL, payload: data.error || data.message });
      }else{
        dispatch({ type: HOME_DELIVERY_GET_SUCCESS, payload: data });
      }

    } catch (error) {
      const message = error.response?.data?.message || error.message;
      dispatch({ type: HOME_DELIVERY_GET_FAIL, payload: message });
    }
  };
}




// export function HomeDelivery() {
//   return async (dispatch) => {
//     dispatch({ type: HOME_DELIVERY_GET_REQUEST });

//     try {
//       const url = `${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}/order/guest/homedelivery_address`;
//       const res = await fetch(url, {
//         method: "GET"
//       });

//       if(!res.ok){
//         const errorData = await res.json();
//         return dispatch({ type: HOME_DELIVERY_GET_FAIL, payload: errorData.message || 'Unknown error occurred' });
//       }
//       const data = await res.json();
//       if (data.error) {
//         return dispatch({ type: HOME_DELIVERY_GET_FAIL, payload: data.message });
//       }

//       dispatch({ type: HOME_DELIVERY_GET_SUCCESS, payload: data });
//     } catch (error) {
//       const message = error.response && error.response.data
//         ? error.response.data.message
//         : error.message;
//       dispatch({ type: HOME_DELIVERY_GET_FAIL, payload: message });
//     }
//   }
// }


